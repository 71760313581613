<template>
  <div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab active>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">General</span>
        </template>
      </b-tab>
      <b-card>
        <b-form-group
          label="Radio Title"
        >
          <b-form-input
            v-model="radio.title"
            name="radio-url"
            placeholder="Radio Title"
          />
        </b-form-group>
        <b-form-group
          label="Contact Email"
        >
          <b-form-input
            v-model="radio.contactEmail"
            name="radio-url"
            placeholder="Contact Email"
          />
        </b-form-group>
        <b-form-group
          label="Stream Url"
        >
          <b-form-input
            v-model="radio.streamUrl"
            name="radio-url"
            placeholder="Stream Url"
          />
        </b-form-group>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @click="updateRadioSettings"
        >
          Save changes
        </b-button>
      </b-card>
    </b-tabs>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BCard, BTabs, BTab, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import radioStoreModule from './radioStoreModule'

export default {
  name: 'RadioSettings',
  components: {
    BTabs,
    BTab,
    BFormInput,
    BFormGroup,
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      storeName: 'radio',
      radio: {
        id: null,
        title: '',
        contactEmail: '',
        streamUrl: '',
      },
    }
  },
  async mounted() {
    this.registerStore()
    await this.fetchRadioSettings()
  },
  destroyed() {
    if (this.$store.hasModule(this.storeName)) this.$store.unregisterModule(this.storeName)
  },
  methods: {
    async fetchRadioSettings() {
      try {
        this.radio = await this.$store.dispatch(`${this.storeName}/fetchRadioSettings`, { limit: '10', page: 1, q: '' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Getting an error while fetching radio settings.',
            icon: 'AlertOctagonIcon',
            variant: 'warning',
          },
        })
        this.radio = {}
      }
    },
    async updateRadioSettings() {
      try {
        const data = this.radio
        await this.$store.dispatch(`${this.storeName}/updateRadioSettings`, data)

        this.$bvToast.toast('Settings has saved successfuly.', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Getting an error while updating radio settings.',
            icon: 'AlertOctagonIcon',
            variant: 'warning',
          },
        })

        this.fetchRadioSettings()
      }
    },
    registerStore() {
      if (!this.$store.hasModule(this.storeName)) this.$store.registerModule(this.storeName, radioStoreModule)
    },
  },
}
</script>

<style>

</style>
