import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRadioSettings(ctx, queryParams) {
      console.log(queryParams)
      const { limit } = queryParams
      const { page } = queryParams
      const { q } = queryParams

      const uri = `/admin/radio/radios?page=${page}&limit=${limit}&q=${q}`

      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then(response => resolve(response.data ? response.data.data[0] : {}))
          .catch(error => reject(error))
      })
    },
    updateRadioSettings(ctx, {
      id, title = '', contactEmail = '', streamUrl = '',
    }) {
      return new Promise((resolve, reject) => {
        axios
          .put('/admin/radio/radios', {
            id, title, contactEmail, streamUrl,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPresenters(ctx, queryParams) {
      console.log(queryParams)
      const { limit } = queryParams
      const { page } = queryParams
      const { q } = queryParams

      const uri = `/admin/radio/programmers?page=${page}&limit=${limit}&q=${q}`

      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
  },
}
